import axios from 'axios';

const BASE_URL = '/api/wg3w-h783.json';
const APP_TOKEN = process.env.VUE_APP_SF_DATA_API_KEY;

export async function fetchIncidents(startDate, endDate, bounds = null, categories = null) {
  try {
    // Format dates to ISO string and handle timezone
    const formatDate = (date) => {
      if (typeof date === 'string') {
        date = new Date(date);
      }
      // Convert to SF local time (UTC-7)
      const sfOffset = -7 * 60; // SF timezone offset in minutes
      const userOffset = date.getTimezoneOffset();
      const offsetDiff = sfOffset - userOffset;
      const adjustedDate = new Date(date.getTime() + offsetDiff * 60 * 1000);
      return adjustedDate.toISOString().slice(0, -1);
    };

    const params = {
      $where: `incident_datetime between '${formatDate(startDate)}' and '${formatDate(endDate)}'`,
      $order: 'incident_datetime DESC',
      $limit: 1000
    };

    if (bounds) {
      params.$where += ` AND latitude between ${bounds.south} and ${bounds.north}`
      params.$where += ` AND longitude between ${bounds.west} and ${bounds.east}`
    }

    if (categories && categories.length > 0) {
      const categoryFilter = categories.map(cat => `'${cat}'`).join(',');
      params.$where += ` AND incident_category IN (${categoryFilter})`;
    }

    console.log('API Query:', params.$where);
    
    const response = await axios.get(BASE_URL, {
      params,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-App-Token': APP_TOKEN
      }
    });
    console.log('API Response:', response.data.length, 'incidents');
    console.log('Sample incident:', response.data[0]);

    return response.data.map(incident => ({
      id: incident.incident_id,
      category: incident.incident_category,
      description: incident.incident_description,
      date: new Date(incident.incident_datetime),
      resolution: incident.resolution,
      intersection: incident.intersection || 'No intersection data',
      latitude: parseFloat(incident.latitude),
      longitude: parseFloat(incident.longitude)
    }));
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
} 