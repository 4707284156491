<template>
  <div class="map-container">
    <h1 class="main-title">San Francisco Crime Map</h1>
    <p class="description">Interactive visualization of recent crime incidents in San Francisco. Data provided by the San Francisco Police Department.</p>
    
    <div class="controls-container">
      <!--    START DATETIME PICKER-->
      <div class="date-picker-container">
        <div class="date-picker">
          <label for="from" class="date-label">From:</label>
          <v-date-picker v-model="startDate" mode="dateTime">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  class="date-input"
                  :value="inputValue"
                  v-on="inputEvents"
                  id="from"
              />
            </template>
          </v-date-picker>
        </div>
        
        <div class="date-picker">
          <label for="to" class="date-label">To:</label>
          <v-date-picker v-model="endDate" mode="dateTime">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  class="date-input"
                  :value="inputValue"
                  v-on="inputEvents"
                  id="to"
              />
            </template>
          </v-date-picker>
        </div>
        
        <div class="category-filter">
          <label class="date-label category-label">Categories:</label>
          <div class="multiselect-wrapper">
            <div class="multiselect-selected" @click="toggleCategoryDropdown">
              <span v-if="searchCategories.length === 0">All Categories</span>
              <span v-else-if="searchCategories.length === 1">{{ searchCategories[0] }}</span>
              <span v-else>{{ searchCategories.length }} categories selected</span>
              <span class="dropdown-arrow">▼</span>
            </div>
            <div class="multiselect-dropdown" v-if="showCategoryDropdown">
              <div class="multiselect-option">
                <label>
                  <input 
                    type="checkbox" 
                    :checked="searchCategories.length === 0" 
                    @click="clearSearchCategories"
                  />
                  <span>All Categories</span>
                </label>
              </div>
              <div 
                v-for="category in predefinedCategories" 
                :key="category" 
                class="multiselect-option"
              >
                <label>
                  <input 
                    type="checkbox" 
                    :value="category" 
                    v-model="searchCategories"
                  />
                  <span>{{ category }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        
        <button class="search-button" @click="resetBounds()">Search in this area</button>
      </div>
      <!--    END DATETIME PICKER-->
      
      <div class="api-status" v-if="error">
        <p>{{ error }}</p>
      </div>
    </div>

    <div class="content-wrapper">
      <div class="map-wrapper">
        <gmap-map
            :center="{lat:37.75692146,lng:-122.4527422}"
            :zoom="13"
            ref="mapRef"
            style="width: 100%; height: 700px;">
          <gmap-info-window
              :options="infoWindowOptions"
              :position="infoWindowPosition"
              :opened="infoWindowOpened"
              @closeclick="handleInfoWindowClose"
          >
            <div class="info-window">
              <h2 v-text="activeIncident.incident_category"></h2>
              <h5 v-text="activeIncident.incident_subcategory"></h5>
              <p class="incident-description" v-text="activeIncident.incident_description"></p>
              <p class="incident-location"><strong>Location:</strong> {{ activeIncident.intersection }}</p>
              <p class="incident-datetime" v-text="activeIncident.incident_datetime"></p>
              <p class="incident-resolution" v-text="activeIncident.resolution"></p>
            </div>
          </gmap-info-window>
          <gmap-marker 
            v-for="incident in processedIncidents" 
            :key="incident.uniqueKey"
            :position="incident.position"
            :clickable="true"
            :draggable="false"
            @click="handleMarkerClicked(incident)"
            :icon="getIcon(incident)"
          ></gmap-marker>
        </gmap-map>
      </div>
      
      <div class="stats-panel">
        <h2 class="stats-title">Incidents by Category</h2>
        
        <div class="filter-controls">
          <button 
            class="filter-button" 
            :class="{ active: selectedCategories.length === 0 }"
            @click="clearCategorySelection"
          >
            All
          </button>
          <div class="filter-buttons-scroll">
            <button 
              v-for="(count, category) in categoryCounts" 
              :key="category"
              class="filter-button"
              :class="{ active: selectedCategories.includes(category) }"
              @click="toggleCategory(category)"
            >
              {{ category }}
            </button>
          </div>
        </div>
        
        <div class="stats-content">
          <div 
            v-for="(count, category) in categoryCounts" 
            :key="category" 
            class="category-item"
            :class="{ 'active-category': selectedCategories.includes(category) || selectedCategories.length === 0 }"
            @click="toggleCategory(category)"
          >
            <div class="category-color" :style="{ backgroundColor: getCategoryColor(category) }"></div>
            <div class="category-name">{{ category }}</div>
            <div class="category-count">{{ count }}</div>
          </div>
          
          <div class="total-incidents">
            <div class="total-label">Total Incidents:</div>
            <div class="total-count">{{ processedIncidents.length }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchIncidents as apiFetchIncidents } from '../services/api';

export default {
  name: "Map",
  data() {
    return {
      incidents: [],
      allIncidents: [], // Store all incidents before filtering
      processedIncidents: [],
      infoWindowOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        }
      },
      activeIncident: {},
      infoWindowOpened: false,
      startDate: new Date(),
      endDate: new Date(),
      error: null, // To display any API errors
      locationMap: new Map(), // Map to track incidents at the same location
      selectedCategories: [], // Array of selected categories for filtering displayed results
      searchCategories: [], // Array of selected categories for search
      showCategoryDropdown: false, // Flag to toggle category dropdown
      predefinedCategories: [
        'Assault',
        'Burglary',
        'Drug Offense',
        'Fraud',
        'Homicide',
        'Larceny Theft',
        'Motor Vehicle Theft',
        'Robbery',
        'Weapons Offense',
        'Weapons Carrying Etc',
        'Vandalism',
        'Malicious Mischief',
        'Stolen Property',
        'Embezzlement',
        'Lost Property',
        'Missing Person',
        'Offences Against The Family And Children',
        'Disorderly Conduct',
        'Fire Report',
        'Traffic Collision',
        'Traffic Violation Arrest',
        'Vehicle Impounded',
        'Vehicle Misplaced',
        'Recovered Vehicle',
        'Warrant',
        'Suspicious Occ',
        'Suspicious',
        'Non-Criminal',
        'Other Miscellaneous',
        'Other Offenses',
        'Other',
        'Miscellaneous Investigation',
        'Case Closure',
        'Unknown'
      ],
      categoryColors: {
        'Homicide': '#ff0000', // red
        'Assault': '#e91e63', // pink
        'Robbery': '#ff9800', // orange
        'Burglary': '#ffc107', // amber
        'Larceny Theft': '#4caf50', // green
        'Motor Vehicle Theft': '#2196f3', // blue
        'Drug Offense': '#9c27b0', // purple
        'Weapons Offense': '#f44336', // red
        'Weapons Carrying Etc': '#f44336', // red
        'Vandalism': '#795548', // brown
        'Malicious Mischief': '#795548', // brown
        'Fraud': '#009688', // teal
        'Other': '#9e9e9e', // gray
        'Other Miscellaneous': '#9e9e9e', // gray
        'Other Offenses': '#9e9e9e', // gray
        'Non-Criminal': '#607d8b', // blue gray
        'Suspicious Occ': '#ff5722', // deep orange
        'Suspicious': '#ff5722' // deep orange
      }
    }
  },
  computed: {
    infoWindowPosition() {
      if (!this.activeIncident.position) {
        return { lat: 0, lng: 0 };
      }
      return this.activeIncident.position;
    },
    categoryCounts() {
      const counts = {};
      
      this.allIncidents.forEach(incident => {
        const category = incident.incident_category || 'Unknown';
        counts[category] = (counts[category] || 0) + 1;
      });
      
      // Sort by count (descending)
      return Object.fromEntries(
        Object.entries(counts).sort((a, b) => b[1] - a[1])
      );
    },
    availableCategories() {
      return Object.keys(this.categoryCounts);
    },
    filteredIncidents() {
      if (this.selectedCategories.length === 0) {
        return this.allIncidents;
      }
      
      return this.allIncidents.filter(incident => 
        this.selectedCategories.includes(incident.incident_category)
      );
    }
  },
  watch: {
    filteredIncidents: {
      handler(newIncidents) {
        this.incidents = newIncidents;
        this.processIncidents();
      },
      deep: true
    }
  },
  created() {
    this.$gtag.pageview({
        page_path: '/',
      })

    // Set date range to past week
    this.endDate = new Date();
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 7); // Go back 7 days

    // Initial fetch of incidents
    this.fetchIncidents();

    // Add event listener for clicks outside of dropdown
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    async fetchIncidents(bounds = null) {
      try {
        this.error = null;
        
        // Convert map bounds to the format expected by the API service
        let boundsObj = null;
        if (bounds) {
          boundsObj = {
            north: bounds.getNorthEast().lat(),
            south: bounds.getSouthWest().lat(),
            east: bounds.getNorthEast().lng(),
            west: bounds.getSouthWest().lng()
          };
        }
        
        // Use the API service to fetch incidents with search categories
        const incidents = await apiFetchIncidents(
          this.startDate, 
          this.endDate, 
          boundsObj,
          this.searchCategories.length > 0 ? this.searchCategories : null
        );
        
        // Convert the API response format to match our expected format
        const mappedIncidents = incidents.map(incident => ({
          row_id: incident.id,
          incident_category: incident.category,
          incident_subcategory: '',
          incident_description: incident.description,
          incident_datetime: incident.date.toLocaleString(),
          resolution: incident.resolution,
          intersection: incident.intersection || 'Intersection not available',
          latitude: incident.latitude.toString(),
          longitude: incident.longitude.toString()
        }));
        
        // Store all incidents
        this.allIncidents = mappedIncidents;
        
        // Let the computed property handle filtering
        this.incidents = this.filteredIncidents;
        this.processIncidents();
      } catch (error) {
        console.error('Error fetching incidents:', error);
        this.error = `Error fetching incidents: ${error.message}`;
        this.allIncidents = [];
        this.incidents = [];
        this.processIncidents();
      }
    },
    processIncidents() {
      // Reset location map
      this.locationMap = new Map();
      
      // First pass: count incidents at each location
      this.incidents.forEach(incident => {
        const key = `${incident.latitude},${incident.longitude}`;
        if (!this.locationMap.has(key)) {
          this.locationMap.set(key, []);
        }
        this.locationMap.get(key).push(incident);
      });
      
      // Second pass: process each incident with offset if needed
      this.processedIncidents = this.incidents.map((incident, index) => {
        const key = `${incident.latitude},${incident.longitude}`;
        const incidentsAtLocation = this.locationMap.get(key);
        const locationIndex = incidentsAtLocation.indexOf(incident);
        
        // Create a copy of the incident with position information
        const processedIncident = { 
          ...incident,
          // Generate a unique key combining the incident ID and index
          uniqueKey: `${incident.row_id || 'unknown'}-${index}`
        };
        
        // Calculate offset if there are multiple incidents at this location
        if (incidentsAtLocation.length > 1) {
          // Calculate a circular pattern around the original point
          const lat = parseFloat(incident.latitude);
          const lng = parseFloat(incident.longitude);
          
          // Offset distance in degrees (approximately 20-30 meters)
          const offsetDistance = 0.0003;
          
          if (incidentsAtLocation.length <= 5) {
            // Simple left-to-right offset for small clusters
            const offset = (locationIndex - (incidentsAtLocation.length - 1) / 2) * offsetDistance;
            processedIncident.position = {
              lat: lat,
              lng: lng + offset
            };
          } else {
            // Circular pattern for larger clusters
            const angleStep = (2 * Math.PI) / incidentsAtLocation.length;
            const angle = locationIndex * angleStep;
            
            // For the first incident, keep it at the center
            if (locationIndex === 0) {
              processedIncident.position = { lat, lng };
            } else {
              processedIncident.position = {
                lat: lat + offsetDistance * Math.sin(angle),
                lng: lng + offsetDistance * Math.cos(angle)
              };
            }
          }
        } else {
          // Single incident at this location, no offset needed
          processedIncident.position = {
            lat: parseFloat(incident.latitude),
            lng: parseFloat(incident.longitude)
          };
        }
        
        return processedIncident;
      });
    },
    getPosition(incident) {
      return incident.position || {
        lat: parseFloat(incident.latitude),
        lng: parseFloat(incident.longitude)
      };
    },
    getCategoryColor(category) {
      return this.categoryColors[category] || this.categoryColors['Other'];
    },
    getIcon(incident) {
      const color = this.getCategoryColor(incident.incident_category);
      // Convert hex to named color for Google Maps markers
      const colorMap = {
        '#ff0000': 'red',     // red
        '#e91e63': 'pink',    // pink
        '#ff9800': 'orange',  // orange
        '#ffc107': 'yellow',  // amber
        '#4caf50': 'green',   // green
        '#2196f3': 'blue',    // blue
        '#9c27b0': 'purple',  // purple
        '#795548': 'brown',   // brown
        '#009688': 'green',   // teal
        '#9e9e9e': 'gray',    // gray
        '#607d8b': 'gray',    // blue gray
        '#ff5722': 'orange',  // deep orange
        '#f44336': 'red'      // red
      };
      
      return {
        'url': `http://maps.google.com/mapfiles/ms/icons/${colorMap[color] || 'red'}-dot.png`
      }
    },
    handleMarkerClicked(incident) {
      this.activeIncident = incident
      this.infoWindowOpened = true
    },
    handleInfoWindowClose() {
      this.activeIncident = {}
      this.infoWindowOpened = false
    },
    toggleCategory(category) {
      const index = this.selectedCategories.indexOf(category);
      if (index === -1) {
        // If not already selected, add it
        this.selectedCategories.push(category);
      } else {
        // If already selected, remove it
        this.selectedCategories.splice(index, 1);
      }
    },
    clearCategorySelection() {
      this.selectedCategories = [];
    },
    toggleCategoryDropdown(event) {
      event.stopPropagation();
      this.showCategoryDropdown = !this.showCategoryDropdown;
    },
    handleClickOutside(event) {
      const dropdown = document.querySelector('.multiselect-wrapper');
      if (dropdown && !dropdown.contains(event.target)) {
        this.showCategoryDropdown = false;
      }
    },
    resetBounds() {
      this.$gtag.event('click', {
        'event_category': 'buttons',
        'event_label': 'search in this map',
      });
      
      this.$refs.mapRef.$mapPromise.then(async (map) => {
        const bounds = map.getBounds();
        await this.fetchIncidents(bounds);
      });
    },
    clearSearchCategories() {
      this.searchCategories = [];
    }
  }
}
</script>

<style scoped>
.map-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.controls-container {
  margin-bottom: 20px;
}

.content-wrapper {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.map-wrapper {
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.stats-panel {
  width: 300px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  height: 700px;
  overflow-y: auto;
}

.stats-title {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
  color: #333;
}

.filter-controls {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-buttons-scroll {
  display: flex;
  overflow-x: auto;
  padding-bottom: 5px;
  gap: 5px;
}

.filter-button {
  background-color: #f1f3f5;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s;
}

.filter-button:hover {
  background-color: #e9ecef;
}

.filter-button.active {
  background-color: #4CAF50;
  color: white;
}

.stats-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.category-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: all 0.2s;
  opacity: 0.7;
}

.category-item:hover {
  background-color: #e9ecef;
}

.category-item.active-category {
  opacity: 1;
  background-color: #f1f8e9;
  border-left: 3px solid #4CAF50;
}

.category-color {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
}

.category-name {
  flex: 1;
  font-weight: 500;
}

.category-count {
  font-weight: 600;
  color: #333;
}

.total-incidents {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
  font-weight: 600;
}

.total-count {
  font-size: 18px;
}

.date-picker-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.date-picker, .category-filter {
  display: flex;
  align-items: center;
}

.date-label {
  font-weight: 600;
  margin-right: 10px;
  color: #495057;
  min-width: 50px;
}

.category-label {
  min-width: 90px;
}

.date-input {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  min-width: 200px;
  background-color: white;
}

.date-input:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.multiselect-wrapper {
  position: relative;
  min-width: 200px;
  width: 100%;
}

.multiselect-selected {
  padding: 8px 12px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  font-size: 10px;
  margin-left: 8px;
}

.multiselect-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin-top: 5px;
}

.multiselect-option {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.multiselect-option:hover {
  background-color: #f8f9fa;
}

.multiselect-option label {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.multiselect-option input {
  margin-right: 8px;
}

.search-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.search-button:hover {
  background-color: #45a049;
}

.api-status {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #f5c6cb;
}

.controls {
  margin: 10px 0;
  padding: 10px;
  background-color: #e9ecef;
  border-radius: 4px;
}

.info-window {
  padding: 5px;
  max-width: 300px;
}

.info-window h2 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
  margin-bottom: 8px;
}

.info-window h5 {
  color: #666;
  margin: 0 0 8px 0;
  font-size: 14px;
}

.info-window p {
  margin: 6px 0;
  font-size: 14px;
  line-height: 1.4;
}

.incident-location {
  color: #1976d2;
  font-weight: 500;
}

.incident-description {
  color: #333;
}

.incident-datetime {
  color: #666;
  font-style: italic;
}

.incident-resolution {
  color: #4caf50;
}

@media (max-width: 992px) {
  .content-wrapper {
    flex-direction: column;
  }
  
  .stats-panel {
    width: 100%;
    height: auto;
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .map-container {
    padding: 0 10px;
  }
  
  .date-picker-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
  
  .date-picker, .category-filter {
    width: 100%;
  }
  
  .date-input {
    flex-grow: 1;
  }
  
  .search-button {
    width: 100%;
  }
}

.main-title {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.description {
  text-align: center;
  color: #666;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
</style>